import React, { useEffect } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { fetchYardList } from "../../../actions/yardActions/YardActions"
import moment from "moment"
import { IndustryType, renderImage, RAIL_CAR } from "../../../utils/helper"

import { Image } from "antd"
const HomeCard = props => {
  /** useEffect(() => {
        props.fetchYardList() 
    }, [])**/

  const handleNext = (single = {}, status, id, item) => {
    const car_type =
      single?.asset_details?.equipment_type_details?.param_description
    if (status == "Inspection Pending") {
      navigate(`/yard/equipment/${id}`, { state: { car_type: car_type } })
    }
    if (status == "In Wash") {
      localStorage.setItem("startTime", item)
      if (IndustryType() === "Food_Grade") {
        navigate(`/yard/selectservice/${id}`, {
          state: {
            item: item ? status : null,
          },
        })
      } else {
        navigate(`/yard/wash_details/${id}`, {
          state: {
            item: item ? item : null,
          },
        })
      }
    }
  }

  return (
    <>
      <section className="home_card">
        <div className="home_card_container">
          {props.yardHandData && props.yardHandData.length > 0 ? (
            props.yardHandData.map(item => (
              <>
                {IndustryType() == RAIL_CAR ? (
                  <div className="oil_home_card_data" key={item.id}>
                    <div className="oil_home_card_data_left">
                      <img
                        src={renderImage(
                          item.asset_details?.equipment_type_details
                            ?.param_description
                        )}
                        alt="card img"
                      />
                    </div>
                    <div className="oil_home_card_data_center">
                      <div className="oil_home_card_data_center_text">
                        Car no: <span>{item.asset_details?.equipment_no}</span>
                      </div>
                      <div className="oil_home_card_data_center_text">
                        Checked In Date:
                        <span>
                          {item?.asset_txns_details?.created_at
                            ? moment
                                .utc(
                                  moment(
                                    item.asset_txns_details.created_at
                                  ).toISOString()
                                )
                                .format("MM/DD/YYYY")
                            : "NA"}
                        </span>
                      </div>
                      {(() => {
                        if (
                          item.asset_details?.equipment_type_details
                            ?.param_description.length <= 20
                        ) {
                          return (
                            <div className="oil_home_card_data_center_text">
                              type:{" "}
                              <span>
                                {
                                  item.asset_details?.equipment_type_details
                                    ?.param_description
                                }
                              </span>
                            </div>
                          )
                        } else {
                          return (
                            <div className="oil_home_card_data_center_text">
                              type:{" "}
                              <span>
                                {item.asset_details?.equipment_type_details?.param_description.slice(
                                  0,
                                  20
                                )}
                                ...
                              </span>
                            </div>
                          )
                        }
                      })()}
                      <div className="oil_home_card_data_center_text">
                        name :<span>{item.customer_details.name}</span>
                      </div>
                    </div>
                    <div className="oil_home_card_data_right">
                      <div className="oil_home_card_data_right1">
                        status:{" "}
                        <span>{item.ticket_status?.param_description}</span>{" "}
                      </div>
                      <div
                        className="oil_home_card_data_right2"
                        style={{
                          backgroundColor:
                            item?.ticket_status?.param_description === "In Wash"
                              ? "#FD8F46"
                              : item?.ticket_status?.param_description ===
                                "Awaiting Approval"
                              ? "#8B8FAE"
                              : item?.ticket_status?.param_description ===
                                "Inspection Pending"
                              ? "#38B6FF"
                              : "primary",
                        }}
                        onClick={() =>
                          handleNext(
                            item,
                            item.ticket_status?.param_description,
                            item.ticket_ref_id,
                            item?.ticket_details[0]?.field_value
                          )
                        }
                      >
                        {item.ticket_status?.param_description ===
                        "Inspection Pending"
                          ? "start inspection"
                          : item.ticket_status?.param_description === "In Wash"
                          ? "in wash"
                          : "awaiting approval"}
                      </div>
                    </div>
                  </div>
                ) : IndustryType() !== "Food_Grade" ? (
                  <div className="oil_home_card_data" key={item.id}>
                    <div className="oil_home_card_data_left">
                      <img
                        src={renderImage(
                          item.asset_details?.equipment_type_details
                            ?.param_description
                        )}
                        alt="card img"
                      />
                    </div>
                    <div className="oil_home_card_data_center">
                      <div className="oil_home_card_data_center_text">
                        equipment no:{" "}
                        <span>{item.asset_details?.equipment_no}</span>
                      </div>
                      <div className="oil_home_card_data_center_text">
                        Checked In Date:
                        <span>
                          {item?.asset_txns_details?.created_at
                            ? moment
                                .utc(
                                  moment(
                                    item.asset_txns_details.created_at
                                  ).toISOString()
                                )
                                .format("MM/DD/YYYY")
                            : "NA"}
                        </span>
                      </div>
                      {(() => {
                        if (
                          item.asset_details?.equipment_type_details
                            ?.param_description.length <= 20
                        ) {
                          return (
                            <div className="oil_home_card_data_center_text">
                              type:{" "}
                              <span>
                                {
                                  item.asset_details?.equipment_type_details
                                    ?.param_description
                                }
                              </span>
                            </div>
                          )
                        } else {
                          return (
                            <div className="oil_home_card_data_center_text">
                              type:{" "}
                              <span>
                                {item.asset_details?.equipment_type_details?.param_description.slice(
                                  0,
                                  20
                                )}
                                ...
                              </span>
                            </div>
                          )
                        }
                      })()}
                      <div className="oil_home_card_data_center_text">
                        name :<span>{item.customer_details.name}</span>
                      </div>
                    </div>
                    <div className="oil_home_card_data_right">
                      <div className="oil_home_card_data_right1">
                        status:{" "}
                        <span>{item.ticket_status?.param_description}</span>{" "}
                      </div>
                      <div
                        className="oil_home_card_data_right2"
                        style={{
                          backgroundColor:
                            item?.ticket_status?.param_description === "In Wash"
                              ? "#FD8F46"
                              : item?.ticket_status?.param_description ===
                                "Awaiting Approval"
                              ? "#8B8FAE"
                              : item?.ticket_status?.param_description ===
                                "Inspection Pending"
                              ? "#38B6FF"
                              : "primary",
                        }}
                        onClick={() =>
                          handleNext(
                            {},
                            item.ticket_status?.param_description,
                            item.ticket_ref_id,
                            item?.ticket_details[0]?.field_value
                          )
                        }
                      >
                        {item.ticket_status?.param_description ===
                        "Inspection Pending"
                          ? "start inspection"
                          : item.ticket_status?.param_description === "In Wash"
                          ? "in wash"
                          : "awaiting approval"}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="oil_home_card_data" key={item.id}>
                    <div className="oil_home_card_data_left">
                      <img
                        src={renderImage(
                          item.asset_details?.equipment_type_details
                            ?.param_description
                        )}
                        alt="card img"
                      />
                    </div>
                    <div className="oil_home_card_data_center">
                      <div className="oil_home_card_data_center_text">
                        Checked In Date:{" "}
                        <span>
                          {moment
                            .utc(moment(item?.created_at).toISOString())
                            .format("MM/DD/YYYY")}
                        </span>
                      </div>
                      <div className="oil_home_card_data_center_text">
                        Trailer Unit No.:
                        <span>{item.asset_details?.equipment_no}</span>
                      </div>
                      <div className="oil_home_card_data_center_text">
                        Customer Name:<span>{item.customer_details?.name}</span>
                      </div>
                    </div>
                    <div className="oil_home_card_data_right">
                      <div className="oil_home_card_data_right1">
                        status:{" "}
                        <span>{item.ticket_status?.param_description}</span>{" "}
                      </div>
                      <div
                        className="oil_home_card_data_right2"
                        style={{
                          backgroundColor:
                            item?.ticket_status?.param_description === "In Wash"
                              ? "#FD8F46"
                              : item?.ticket_status?.param_description ===
                                "Awaiting Approval"
                              ? "#8B8FAE"
                              : item?.ticket_status?.param_description ===
                                "Inspection Pending"
                              ? "#38B6FF"
                              : "primary",
                        }}
                        onClick={() =>
                          handleNext(
                            {},
                            item.ticket_status?.param_description,
                            item.ticket_ref_id,
                            item?.ticket_details[0]?.field_value
                          )
                        }
                      >
                        {item.ticket_status?.param_description ===
                        "Inspection Pending"
                          ? "start inspection"
                          : item.ticket_status?.param_description === "In Wash"
                          ? "in wash"
                          : "awaiting approval"}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))
          ) : props.yardHandData != null ? (
            props.inSearchState ? (
              <div>There are no data related to your search</div>
            ) : (
              <div>You have no more tickets in queue!</div>
            )
          ) : (
            <div>Fetching data...</div>
          )}
        </div>
      </section>
    </>
  )
}
const mapStateToProps = state => ({
  truckData: state.truck.truckData,
  // yardHandData: state.yardHandData
})
export default connect(mapStateToProps, { fetchYardList })(HomeCard)
